import { Component } from '@angular/core';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AlertController, Platform } from '@ionic/angular';
import { ServerApiService } from './server-api.service';
import { AppUpdate } from './models/appupdate.model';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  constructor(
    private serverApiService: ServerApiService,
    private alertCtrl: AlertController,
    private platform: Platform,
    private appVersion: AppVersion,
    private inAppBrowser: InAppBrowser) {
      setTimeout(() => {
        this.platform.ready().then(()=>{
          this.checkForUpdate();
        });
      }, 3000);
    }

  checkForUpdate(){
    this.serverApiService.getLatestAppVersion()
    .subscribe(
      async (res:AppUpdate) => {
        console.log('App Version Check',res);

        if(!res.customerapp.enabled){
          //app maintenance
          this.presentAlert('App Maintenance','Currently our app is under maintenance. We will come back soon!');
        }else{
          //check for version update
          const versionNumber = await this.appVersion.getVersionNumber();
          const splittedVersion = versionNumber.split('.');

          let serverVersion = ['0','0','0']
          if(this.platform.is('android'))
            serverVersion = res.customerapp.android.split('.');
          else
            serverVersion = res.customerapp.ios.split('.');

          console.log(splittedVersion,serverVersion);

          if(serverVersion[0]>splittedVersion[0]){
            this.presentAlert('Update Available',`We have made some important changes in the app's functionality. Please! update your app to continue.`,'Update');
          }else if(serverVersion[0]===splittedVersion[0] && serverVersion[1]>splittedVersion[1]){
            this.presentAlert('Update Available','The new version of the app is available!','Update Now',true);
          }
        }
      },
      (err) => console.log(err)
      );
  }

  openAppStoreEntry(){
    if(this.platform.is('android')){
      this.inAppBrowser.create('https://play.google.com/store/apps/details?id=com.aaryaksolutions.unbox&hl=en_IN&gl=US','_system');
    } else {
      this.inAppBrowser.create('itms-apps://itunes.apple.com/app/id1559733578','_system');
    }
  }

  async presentAlert(header: string, message: string, buttonText = '', allowClose = false){
    const buttons: any = [];

    if(buttonText != ''){
      buttons.push({
        text: buttonText,
        handler:() =>{
          this.openAppStoreEntry();
        }
      })
    }

    if(allowClose){
      buttons.push({
        text: 'Close',
        role: 'cancel'
      })
    }

    const alert = await this.alertCtrl.create({
      header: header,
      message: message,
      buttons: buttons,
      backdropDismiss: allowClose
    });
    await alert.present();
  }
}
