import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilService } from './util.service';
import { map, catchError, tap, switchMap, take } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { BehaviorSubject, from } from 'rxjs';
import { AuthModel } from './models/auth.model';
import { Plugins } from '@capacitor/core';
import { AuthResponseModel } from './models/auth-response.model';
import { AddressModel } from './models/address.model';
import { DeliveryLocationModel } from './models/delivery-location.model';
import { RegisterCustomerModel } from './models/register-customer.model';
import { RestaurantModel } from './models/restaurant.model';
import { SliderModel } from './models/slider.model';
import { RestaurantInfoModel } from './models/restaurant-info.model';
import { MenuModel } from './models/menu.model';
import { CartAvailbilityModel } from './models/cart-availability.model';
import { RestaurantCartModel } from './models/restaurant-cart.model';
import { OrderModel } from './models/order.model';
import { CouponModel } from './models/coupon.model';
import { PaymentResponseModel } from './models/payment-response.model';
import { FirebaseTokenModel } from './models/firebase-token.model';
import { MyOrderModel } from './models/my-order.model';
import { MyOrderDetailsModel } from './models/my-order-details.model';
import { AutoCompleteModel } from './models/auto-complete.model';
import { MenuSearchModel } from './models/menu-search.model';
import { AppUpdate } from './models/appupdate.model';
import { OrderReviewsModel } from './models/order-reviews.model';
import { ReorderModel } from './models/reorder.model';
import { MenuAddonModel } from './models/menu-addon.model';
import { MyOrderTrackingDetailsModel } from './models/my-order-tracking-details.model';

@Injectable({
  providedIn: 'root'
})
export class ServerApiService {

  private _user = new BehaviorSubject<AuthModel>(null);
  private _deliveryLocation = new BehaviorSubject<DeliveryLocationModel>(null);

  constructor(
    private http:HttpClient,
    private utilService:UtilService
  ) { }

  forgotPassword(userName : string){
    this.utilService.username = userName;
    return this.http.post(`${environment.apiEndpoint}/api/Account/ForgotPassword?UserName=${userName}`,null)
    .pipe(map(
        (response: Response) => {
            return response;
        }
    ),catchError(
        (error: Response) => {
            return throwError(error);
        }
    ));
  }

  register(model : RegisterCustomerModel){
    return this.http.post(`${environment.apiEndpoint}/api/CustomerAppV1/CreateCustomer`,model)
    .pipe(map(
        (response: Response) => {
            return response;
        }
    ),catchError(
        (error: Response) => {
            return throwError(error);
        }
    ));
  }

  login(obj:any) {
    const headers =  new HttpHeaders({"Content-Type":"application/x-www-form-urlencoded"});
    return this.http.post<AuthResponseModel>(`${environment.apiEndpoint}/token`,obj)
    .pipe(tap(this.setUserData.bind(this)));
  }

  logout() {
    this._user.next(null);
    Plugins.Storage.remove({ key: 'authData' });
  }

  private setUserData(userData: AuthResponseModel) {
    const user = new AuthModel(
      userData.userId,
      userData.userName,
      userData.userFullName,
      userData.access_token
    );
    this._user.next(user);
    this.storeAuthData(
      userData.userId,
      userData.userName,
      userData.userFullName,
      userData.access_token
    );
  }

  private storeAuthData(
    userId: number,
    userName: string,
    userFullName: string,
    token: string
  ) {
    const data = JSON.stringify({
      userId: userId,
      userName: userName,
      userFullName: userFullName,
      accessToken: token
    });
    Plugins.Storage.set({ key: 'authData', value: data });
  }

  autoLoginGuard() {
    return from(Plugins.Storage.get({ key: 'authData' })).pipe(
      map(storedData => {
        if (!storedData || !storedData.value) {
          return null;
        }
        const parsedData = JSON.parse(storedData.value) as {
          userId: number;
          userName: string;
          userFullName: string;
          accessToken: string;
        };
        const user = new AuthModel(
          parsedData.userId,
          parsedData.userName,
          parsedData.userFullName,
          parsedData.accessToken
        );
        return user;
      }),
      tap(user => {
        if (user) {
          this._user.next(user);
        }
      }),
      map(user => {
        return !!user;
      })
    );
  }

  get userIsAuthenticated() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return !!user.accessToken;
        } else {
          return false;
        }
      })
    );
  }

  get hasSetDeliveryLocation() {
    return this._deliveryLocation.asObservable().pipe(
      map(location => {
        if (location) {
          return !!location;
        } else {
          return false;
        }
      })
    );
  }

  get user(){
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return user;
        } else {
          return null;
        }
      })
    );
  }

  get authorizationHeader(){
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return new HttpHeaders({"Authorization":"Bearer "+user.accessToken});
        } else {
          return null;
        }
      })
    );
  }

  handleHttpError(error:Response){
    if(error.status == 401){
      this.logout();
    }
    return throwError(error);
  }

  getAddresses(){
    return Plugins.Storage.get({key:'addresses'});
  }

  async saveAddress(model: AddressModel){
    await this.getAddresses()
    .then(async data =>{
      let addresses : AddressModel[] = [];
      if(data && data.value){
        addresses = JSON.parse(data.value);
      }
      addresses.push(model);
      await Plugins.Storage.set({key:'addresses',value:JSON.stringify(addresses)});
      console.log('address saved!');
    });
  }

  async removeAddress(index:number){
    await this.getAddresses()
    .then(async data =>{
      let addresses : AddressModel[] = [];
      if(data && data.value){
        addresses = JSON.parse(data.value);
      }
      addresses.splice(index,1);
      if(addresses.length == 0){
        await Plugins.Storage.remove({key:'addresses'});
      }else{
        await Plugins.Storage.set({key:'addresses',value:JSON.stringify(addresses)});
      }
      console.log('address removed!');
    });
  }

  get deliveryLocation(){
    return this._deliveryLocation.asObservable().pipe(
      map(loc => {
        if (loc) {
          return loc;
        } else {
          return null;
        }
      })
    );
  }

  setDeliveryLocation(location: DeliveryLocationModel){
    let lat = parseFloat(location.GeoLocation.split(',')[0].toString());
    let lng = parseFloat(location.GeoLocation.split(',')[1].toString());
    return this.http.get(`${environment.apiEndpoint}/api/CustomerAppV1/GetAreaID?latitude=${lat}&longitude=${lng}`)
    .pipe(map(
        (response: any) => {
            location.AreaID = parseInt(response)
            this._deliveryLocation.next(location);
            return response;
        }
    ),catchError(
        (error: Response) => {
            return throwError(error);
        }
    ));
  }

  //restarants
  getRestaurants(areaId: number,active:boolean,hotelType:string,
    category:string,latitude:number,longitude:number){
    return this.http.get(`${environment.apiEndpoint}/api/CustomerAppV4/Hotels?AreaID=${areaId}&Active=${active}&HotelType=${hotelType}&Category=${category}&lattitude=${latitude}&longitude=${longitude}`)
    .pipe(map(
        (response: RestaurantModel[]) => {
            return response;
        }
    ),catchError(
        (error: Response) => {
            return throwError(error);
        }
    ));
  }

  getRestaurantsHome(areaId: number,filterType:string,latitude:number,longitude:number){
    return this.http.get(`${environment.apiEndpoint}/api/CustomerAppV4/TopHotels?AreaID=${areaId}&FilterType=${filterType}&lattitude=${latitude}&longitude=${longitude}`)
    .pipe(map(
        (response: RestaurantModel[]) => {
            return response;
        }
    ),catchError(
        (error: Response) => {
            return throwError(error);
        }
    ));
  }

  getPreOrderHome(areaId: number,filterType:string,latitude:number,longitude:number){
    return this.http.get(`${environment.apiEndpoint}/api/CustomerAppV4/TopPreOrder?AreaID=${areaId}&FilterType=${filterType}&lattitude=${latitude}&longitude=${longitude}`)
    .pipe(map(
        (response: RestaurantModel[]) => {
            return response;
        }
    ),catchError(
        (error: Response) => {
            return throwError(error);
        }
    ));
  }

  getSlider(areaId: number, ShowAs: string){
    return this.http.get(`${environment.apiEndpoint}/api/CustomerAppV1/AllImageList?AreaID=${areaId}&ShowAs=${ShowAs}`)
    .pipe(map(
        (response: SliderModel[]) => {
            return response;
        }
    ),catchError(
        (error: Response) => {
            return throwError(error);
        }
    ));
  }

  getCouponSlider(areaId: number){
    return this.http.get(`${environment.apiEndpoint}/api/CustomerAppV1/AllImageList?AreaID=${areaId}&ShowAs=Coupon`)
    .pipe(map(
        (response: SliderModel[]) => {
            return response;
        }
    ),catchError(
        (error: Response) => {
            return throwError(error);
        }
    ));
  }

  getNotiText(areaId: number){
    return this.http.get(`${environment.apiEndpoint}/api/CustomerAppV1/GetNotiText?AreaID=${areaId}`)
    .pipe(map(
        (response: Response) => {
            return response;
        }
    ),catchError(
        (error: Response) => {
            return throwError(error);
        }
    ));
  }

  //menus
  getMenus(hotelUrl:string,active:boolean){
    return this.http.get(`${environment.apiEndpoint}/api/CustomerAppV3/MenuList?HotelUrl=${hotelUrl}&Active=${active}`)
    .pipe(map(
        (response: MenuModel[]) => {
            return response;
        }
    ),catchError(
        (error: Response) => {
            return throwError(error);
        }
    ));
  }

  getRestaurantInfoForMenu(hotelUrl:string,latitude:number,longitude:number){
    return this.http.get(`${environment.apiEndpoint}/api/CustomerAppV3/HotelDetailsForMenu?HotelUrl=${hotelUrl}&lattitude=${latitude}&longitude=${longitude}`)
    .pipe(map(
        (response: RestaurantInfoModel) => {
            return response;
        }
    ),catchError(
        (error: Response) => {
            return throwError(error);
        }
    ));
  }

  getRestaurantInfoForCart(hotelId:number,latitude:number,longitude:number){
    return this.http.get(`${environment.apiEndpoint}/api/CustomerAppV4/HotelDetailsForCart?HotelID=${hotelId}&lattitude=${latitude}&longitude=${longitude}`)
    .pipe(map(
        (response: RestaurantCartModel) => {
            return response;
        }
    ),catchError(
        (error: Response) => {
            return throwError(error);
        }
    ));
  }

  //cart and checkout
  getCartAvailability(model:CartAvailbilityModel[]){
    return this.http.post(`${environment.apiEndpoint}/api/CustomerAppV1/IsCartActive`,model)
    .pipe(map(
        (response: CartAvailbilityModel[]) => {
            return response;
        }
    ),catchError(
        (error: Response) => {
            return throwError(error);
        }
    ));
  }

  getIsCODAvailable(areaId: number){
    return this.http.get(`${environment.apiEndpoint}/api/CustomerAppV1/IsCODAvailable?AreaID=${areaId}`)
    .pipe(map(
        (response: Response) => {
            return response;
        }
    ),catchError(
        (error: Response) => {
            return throwError(error);
        }
    ));
  }

  getCoupons(hotelId: number){
    return this.http.get(`${environment.apiEndpoint}/api/CustomerAppV4/CouponList?HotelID=${hotelId}`)
    .pipe(map(
        (response: CouponModel[]) => {
            return response;
        }
    ),catchError(
        (error: Response) => {
            return throwError(error);
        }
    ));
  }

  getGiftCoupon(hotelId: number,couponCode:string,customerId:string){
    return this.http.get(`${environment.apiEndpoint}/api/CustomerAppV4/GiftVoucherDetails?HotelID=${hotelId}&CouponCode=${couponCode}&CustomerID=${customerId}`)
    .pipe(map(
        (response: CouponModel) => {
            return response;
        }
    ),catchError(
        (error: Response) => {
            return throwError(error);
        }
    ));
  }

  //placeorder
  submitOrder(order: OrderModel){
    return this.authorizationHeader
    .pipe(take(1),switchMap(response => {
      return this.http.post(`${environment.apiEndpoint}/api/CustomerAppV4/CreateOrder`, order, {headers:response})
      .pipe(map(
          (response: Response) => {
              return response;
          }
      ),catchError(
          (error: Response) => {
              return this.handleHttpError(error);
          }
      ));
    })
    );
  }

  updatePaymentStatus(paymentResponse: PaymentResponseModel){
    return this.authorizationHeader
    .pipe(take(1),switchMap(response => {
      return this.http.post(`${environment.apiEndpoint}/api/CustomerAppV4/UpdatePaymentStatus`, paymentResponse, {headers:response})
      .pipe(map(
          (response: Response) => {
              return response;
          }
      ),catchError(
          (error: Response) => {
              return this.handleHttpError(error);
          }
      ));
    })
    );
  }

  //fcm token
  saveFCMToken(token: FirebaseTokenModel){
    return this.authorizationHeader
    .pipe(take(1),switchMap(response => {
      return this.http.post(`${environment.apiEndpoint}/api/CustomerAppV1/SetFirebaseToken`, token, {headers:response})
      .pipe(map(
          (response: Response) => {
              return response;
          }
      ),catchError(
          (error: Response) => {
              return this.handleHttpError(error);
          }
      ));
    })
    );
  }

  //orders
  cancelOrder(id: number){
    return this.authorizationHeader
    .pipe(take(1),switchMap(response => {
      return this.http.post(`${environment.apiEndpoint}/api/CustomerAppV1/CancelOrder?OrderID=${id}`, null, {headers:response})
      .pipe(map(
          (response: Response) => {
              return response;
          }
      ),catchError(
          (error: Response) => {
              return this.handleHttpError(error);
          }
      ));
    })
    );
  }

  recentOrders(OrderCount: number){
    return this.authorizationHeader
    .pipe(take(1),switchMap(response => {
      return this.http.get(`${environment.apiEndpoint}/api/CustomerAppV4/MyLatestOrders?OrderCount=${OrderCount}`, {headers:response})
      .pipe(map(
          (response: MyOrderModel[]) => {
              return response;
          }
      ),catchError(
          (error: Response) => {
              return this.handleHttpError(error);
          }
      ));
    })
    );
  }

  orderDetails(id: number){
    return this.authorizationHeader
    .pipe(take(1),switchMap(response => {
      return this.http.get(`${environment.apiEndpoint}/api/CustomerAppV1/OrderDetails?OrderID=${id}`, {headers:response})
      .pipe(map(
          (response: MyOrderDetailsModel) => {
              return response;
          }
      ),catchError(
          (error: Response) => {
              return this.handleHttpError(error);
          }
      ));
    })
    );
  }

  orderDetailsForTracking(id: number){
    return this.authorizationHeader
    .pipe(take(1),switchMap(response => {
      return this.http.get(`${environment.apiEndpoint}/api/CustomerAppV4/OrderTrackingDetails?OrderID=${id}`, {headers:response})
      .pipe(map(
          (response: MyOrderTrackingDetailsModel) => {
              return response;
          }
      ),catchError(
          (error: Response) => {
              return this.handleHttpError(error);
          }
      ));
    })
    );
  }

  rateOrder(order: OrderReviewsModel){
    return this.authorizationHeader
    .pipe(take(1),switchMap(response => {
      return this.http.post(`${environment.apiEndpoint}/api/CustomerAppV4/SendCustomerReviews`, order, {headers:response})
      .pipe(map(
          (response: Response) => {
              return response;
          }
      ),catchError(
          (error: Response) => {
              return this.handleHttpError(error);
          }
      ));
    })
    );
  }

  //search
  searchAutoComplete(areaId:number,searchText:string){
    return this.http.get(`${environment.apiEndpoint}/api/CustomerAppV2/MenuByTextSearchAutoComplete?AreaID=${areaId}&search=${searchText}`)
    .pipe(map(
        (response: AutoCompleteModel[]) => {
            return response;
        }
    ),catchError(
        (error: Response) => {
            return this.handleHttpError(error);
        }
    ));
  }

  searchMenus(areaId:number,searchText:string,latitude:number,longitude:number){
    return this.http.get(`${environment.apiEndpoint}/api/CustomerAppV3/SearchedMenus?AreaID=${areaId}&search=${searchText}&lattitude=${latitude}&longitude=${longitude}`)
    .pipe(map(
        (response: MenuSearchModel[]) => {
            return response;
        }
    ),catchError(
        (error: Response) => {
            return this.handleHttpError(error);
        }
    ));
  }

  searchRestaurant(areaId: number,search:string,latitude:number,longitude:number){
    return this.http.get(`${environment.apiEndpoint}/api/CustomerAppV2/SearchedHotels?AreaID=${areaId}&search=${search}&lattitude=${latitude}&longitude=${longitude}`)
    .pipe(map(
        (response: RestaurantModel[]) => {
            return response;
        }
    ),catchError(
        (error: Response) => {
            return throwError(error);
        }
    ));
  }

  //get app version and status
  getLatestAppVersion(){
    return this.http.get(`${environment.apiEndpoint}/api/Values/GetLatestAppVersion`)
    .pipe(map(
        (response: AppUpdate) => {
            return response;
        }
    ),catchError(
        (error: Response) => {
            return throwError(error);
        }
    ));
  }

  //get Unbox Cash
  getUnboxCash(){
    return this.authorizationHeader
    .pipe(take(1),switchMap(response => {
      return this.http.get(`${environment.apiEndpoint}/api/CustomerAppV1/MyUnboxCash`,{headers:response})
      .pipe(map(
          (response: Response) => {
              return response;
          }
      ),catchError(
          (error: Response) => {
              return throwError(error);
          }
      ));
    })
    );
  }

  //get Unbox Cash
  getReorderMenu(orderId:number){
    return this.authorizationHeader
    .pipe(take(1),switchMap(response => {
      return this.http.get(`${environment.apiEndpoint}/api/CustomerAppV3/ReorderMenu?OrderID=${orderId}`,{headers:response})
      .pipe(map(
          (response: ReorderModel) => {
              return response;
          }
      ),catchError(
          (error: Response) => {
              return throwError(error);
          }
      ));
    })
    );
  }

  //addons
  getAddOns(mainMenuID:number){
    return this.http.get(`${environment.apiEndpoint}/api/CustomerAppV3/MenuAddOn?MenuID=${mainMenuID}`)
    .pipe(map(
        (response: MenuAddonModel[]) => {
            return response;
        }
    ),catchError(
        (error: Response) => {
            return throwError(error);
        }
    ));
  }


}
