import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  // CONSTANTS - APP WIDE
  public _HomeDelivery = 'Home Delivery';
  public _Pickup = 'Pickup';
  public _TableBooking = 'Table Booking';
  public _OnlinePayment = 'Online Payment';
  public _CashOnDelivery = 'Cash On Delivery';

  public username : string;
  constructor(public toastController: ToastController, private alertCtrl: AlertController) { }

  async showToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000
    });
    toast.present();
  }

  async showToastError() {
    const toast = await this.toastController.create({
      message: "Something went wrong!",
      duration: 2000
    });
    toast.present();
  }

  async showAlert(title : string, message : string){
    const alert = await this.alertCtrl.create({
        header:title,
        message:message,
        buttons: ['Okay']
    });
    alert.present();
  }

}
