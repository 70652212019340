import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { take, tap, switchMap } from 'rxjs/operators';
import { ServerApiService } from 'src/app/server-api.service';
import { DeliveryLocationModel } from '../models/delivery-location.model';

@Injectable({
  providedIn: 'root'
})
export class LocationGuard implements CanLoad {
  constructor(private serverApiService: ServerApiService, private router: Router) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.serverApiService.hasSetDeliveryLocation.pipe(
      take(1),
      switchMap(hasSetLocation => {
        if (!hasSetLocation) {
          return of(false);
        } else {
          return of(true);
        }
      }),
      tap(hasSetLocation => {
        if (!hasSetLocation) {
          this.router.navigateByUrl('/location-fetching');
          //this.router.navigateByUrl('/address-select');
        }
      })
    );
  }
}
