import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LocationGuard } from './guards/location.guard';

const routes: Routes = [
  { path: '',
    redirectTo: 'tabs',
    pathMatch: 'full'
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canLoad: [LocationGuard]
  },
  {
    path: 'location-select',
    loadChildren: () => import('./location-select/location-select.module').then( m => m.LocationSelectPageModule)
  },
  {
    path: 'address-select',
    loadChildren: () => import('./address-select/address-select.module').then( m => m.AddressSelectPageModule)
  },
  {
    path: 'addon-modal',
    loadChildren: () => import('./shared/addon-modal/addon-modal.module').then( m => m.AddonModalPageModule)
  },
  {
    path: 'senior-citizen',
    loadChildren: () => import('./senior-citizen/senior-citizen.module').then( m => m.SeniorCitizenPageModule)
  },
  {
    path: 'location-fetching',
    loadChildren: () => import('./location-fetching/location-fetching.module').then( m => m.LocationFetchingPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
